import { defineStore } from 'pinia';
import pjson from '~/../package.json';

export const titleDelimiter = '•';
const baseTitle = `Personalmanagement`;

export const useAppStore = defineStore('app', () => {
  const title = ref<string>('');
  const version = `v${pjson.version}`;

  //#region drawer
  const drawerEnabled = ref<boolean>(true);
  const drawerOpen = ref<boolean>(true);

  const toggleDrawer = () => {
    drawerOpen.value = !drawerOpen.value;
  };
  //#endregion

  const documentTitle = computed(() => {
    if (title.value === '') {
      return '';
    } else {
      return `${title.value} ${titleDelimiter} ${baseTitle}`;
    }
  });

  watch(documentTitle, () => {
    document.title = documentTitle.value;
  });

  return {
    version,
    drawerEnabled,
    drawerOpen,
    title,
    toggleDrawer,
    documentTitle,
  };
});
